var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"pl-2 mb-2"},[_vm._v(_vm._s(_vm.$t('user_dropdown.settings')))]),_c('ValidationObserver',{ref:"validation-observer"},[_c('div',{staticClass:"col-12 d-flex flex-wrap card__box-shadow mb-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","size":"lg","placeholder":_vm.$t('name')},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('surname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('surname'),"label-for":"surname"}},[_c('b-form-input',{attrs:{"id":"surname","size":"lg","placeholder":_vm.$t('surname')},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('second_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('second_name'),"label-for":"second_name"}},[_c('b-form-input',{attrs:{"id":"second_name","size":"lg","placeholder":_vm.$t('second_name')},model:{value:(_vm.user.second_name),callback:function ($$v) {_vm.$set(_vm.user, "second_name", $$v)},expression:"user.second_name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('email'),"label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","size":"lg","placeholder":_vm.$t('email')},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('phone'),"label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","size":"lg","placeholder":_vm.$t('phone')},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('verified'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('verified'),"label-for":"verified"}},[_c('b-form-checkbox',{attrs:{"switch":"","id":"verified","disabled":true,"size":"lg","placeholder":_vm.$t('verified')},model:{value:(_vm.user.verified),callback:function ($$v) {_vm.$set(_vm.user, "verified", $$v)},expression:"user.verified"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"row "},[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-4",attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.updateSettings.apply(null, arguments)}}},[_vm._v(" Сохранить ")])],1)],1)]),_c('h2',{staticClass:"pl-2 mb-2"},[_vm._v(_vm._s(_vm.$t('password')))]),_c('ValidationObserver',{ref:"validation-observer"},[_c('div',{staticClass:"col-12 d-flex flex-wrap card__box-shadow mb-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('password'),"label-for":"old_password"}},[_c('b-form-input',{attrs:{"id":"old_password","size":"lg","type":"password","placeholder":_vm.$t('password')},model:{value:(_vm.password.old_password),callback:function ($$v) {_vm.$set(_vm.password, "old_password", $$v)},expression:"password.old_password"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('new_password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('new_password'),"label-for":"new_password"}},[_c('b-form-input',{attrs:{"id":"new_password","type":"password","size":"lg","placeholder":_vm.$t('new_password')},model:{value:(_vm.password.new_password),callback:function ($$v) {_vm.$set(_vm.password, "new_password", $$v)},expression:"password.new_password"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('repeat_password'),"rules":"required|confirmed:new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('repeat_password'),"label-for":"repeat_password"}},[_c('b-form-input',{attrs:{"id":"repeat_password","size":"lg","type":"password","placeholder":_vm.$t('repeat_password')},model:{value:(_vm.password.repeat_password),callback:function ($$v) {_vm.$set(_vm.password, "repeat_password", $$v)},expression:"password.repeat_password"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"row "},[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-4",attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_vm._v(" Сохранить ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }