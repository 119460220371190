<template>
    <div>

        <h2 class="pl-2 mb-2">{{ $t('user_dropdown.settings') }}</h2>

        <ValidationObserver ref="validation-observer">

            <div class="col-12 d-flex flex-wrap card__box-shadow mb-2">

                <!--                &lt;!&ndash;    FAVICON     &ndash;&gt;-->
                <!--                <b-col cols="6" class="mb-2 d-flex flex-column align-items-center">-->
                <!--                    <label class="mb-1">FAVICON</label>-->
                <!--                    <div class="card__box-shadow" style="position: relative;width: 250px;height: 250px">-->
                <!--                        <input @input="uploadIcon" type="import" accept="image/*"-->
                <!--                               style="z-index: 30;position: absolute;top: 0;bottom: 0;left: 0;right: 0;width: 100%;height: 100%">-->
                <!--                        <img :src="favicon.path" alt=""-->
                <!--                             style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;width: 100%;height: 100%"-->
                <!--                        >-->
                <!--                    </div>-->
                <!--                </b-col>-->

                <!--                &lt;!&ndash;    LOGO     &ndash;&gt;-->
                <!--                <b-col cols="6" class="mb-2 d-flex flex-column align-items-center">-->
                <!--                    <label class="mb-1">LOGO</label>-->
                <!--                    <div class="card__box-shadow" style="position: relative;width: 250px;height: 250px">-->
                <!--                        <input @input="uploadLogo" type="import" accept="image/*"-->
                <!--                               style="z-index: 30;position: absolute;top: 0;bottom: 0;left: 0;right: 0;width: 100%;height: 100%">-->
                <!--                        <img :src="logo.path" alt=""-->
                <!--                             style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;width: 100%;height: 100%"-->
                <!--                        >-->
                <!--                    </div>-->
                <!--                </b-col>-->

                <!--  NAME  -->
                <b-col cols="4">
                    <ValidationProvider :name="$t('name')" rules="required" v-slot="{errors}">
                        <b-form-group
                            :label="$t('name')"
                            label-for="name"
                        >
                            <b-form-input
                                v-model="user.first_name"
                                id="name"
                                size="lg"
                                :placeholder="$t('name')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider :name="$t('surname')" rules="required" v-slot="{errors}">
                        <b-form-group
                            :label="$t('surname')"
                            label-for="surname"
                        >
                            <b-form-input
                                v-model="user.last_name"
                                id="surname"
                                size="lg"
                                :placeholder="$t('surname')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider :name="$t('second_name')" rules="required" v-slot="{errors}">
                        <b-form-group
                            :label="$t('second_name')"
                            label-for="second_name"
                        >
                            <b-form-input
                                v-model="user.second_name"
                                id="second_name"
                                size="lg"
                                :placeholder="$t('second_name')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

                <!--    EMAIL    -->
                <b-col cols="5">
                    <ValidationProvider :name="$t('email')" rules="required" v-slot="{errors}">
                        <b-form-group
                            :label="$t('email')"
                            label-for="email"
                        >
                            <b-form-input
                                v-model="user.email"
                                id="email"
                                size="lg"
                                :placeholder="$t('email')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

                <!--    PHONE    -->
                <b-col cols="5">
                    <ValidationProvider :name="$t('phone')" rules="required" v-slot="{errors}">
                        <b-form-group
                            :label="$t('phone')"
                            label-for="phone"
                        >
                            <b-form-input
                                v-model="user.phone"
                                id="phone"
                                size="lg"
                                :placeholder="$t('phone')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

                <!--    PHONE    -->
                <b-col cols="2">
                    <ValidationProvider :name="$t('verified')" rules="required" v-slot="{errors}">
                        <b-form-group
                            :label="$t('verified')"
                            label-for="verified"
                        >
                            <b-form-checkbox
                                switch
                                v-model="user.verified"
                                id="verified"
                                :disabled="true"
                                size="lg"
                                :placeholder="$t('verified')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>


            </div>

            <!--  SAVE BTN  -->
            <div class="row ">
                <b-col
                    cols="12"
                    class="d-flex justify-content-end"
                >
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        @click.prevent="updateSettings"
                        class="mb-4"
                    >
                        Сохранить
                    </b-button>

                </b-col>
            </div>

        </ValidationObserver>


        <h2 class="pl-2 mb-2">{{ $t('password') }}</h2>

        <ValidationObserver ref="validation-observer">

            <div class="col-12 d-flex flex-wrap card__box-shadow mb-2">

                <!--  PASSWORD  -->
                <b-col cols="4">
                    <ValidationProvider :name="$t('password')" rules="required" v-slot="{errors}">
                        <b-form-group
                            :label="$t('password')"
                            label-for="old_password"
                        >
                            <b-form-input
                                v-model="password.old_password"
                                id="old_password"
                                size="lg"
                                type="password"
                                :placeholder="$t('password')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider :name="$t('new_password')" rules="required" v-slot="{errors}">
                        <b-form-group
                            :label="$t('new_password')"
                            label-for="new_password"
                        >
                            <b-form-input
                                v-model="password.new_password"
                                id="new_password"
                                type="password"
                                size="lg"
                                :placeholder="$t('new_password')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

                <b-col cols="4">
                    <ValidationProvider :name="$t('repeat_password')" rules="required|confirmed:new_password"
                                        v-slot="{errors}">
                        <b-form-group
                            :label="$t('repeat_password')"
                            label-for="repeat_password"
                        >
                            <b-form-input
                                v-model="password.repeat_password"
                                id="repeat_password"
                                size="lg"
                                type="password"
                                :placeholder="$t('repeat_password')"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </b-col>

            </div>

            <!--  SAVE BTN  -->
            <div class="row ">
                <b-col
                    cols="12"
                    class="d-flex justify-content-end"
                >
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        @click.prevent="updatePassword"
                        class="mb-4"
                    >
                        Сохранить
                    </b-button>

                </b-col>
            </div>

        </ValidationObserver>
    </div>
</template>

<script>
import {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BButton,
    BTable,
    BBadge,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea
} from 'bootstrap-vue'
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastNotification from '@core/components/toastification/ToastNotification.vue'

export default {
    name: 'Settings',
    components: {
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BSpinner,
        BCard,
        BFormTextarea,
        ToastNotification
    },
    directives: {
        Ripple
    },

    data() {
        return {
            user: {},
            password: {
                old_password: null,
                new_password: null,
                repeat_password: null
            }
        }
    },

    async created() {
        await this.getSettings()
    },

    methods: {

        showToast(variant, text, icon) {
            this.$toast({
                component: ToastNotification,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            })
        },


        async getSettings() {
            await api.auth.getMe()
                .then((res) => {
                    this.user = res.data
                })
                .catch((error) => {
                    this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                    console.log(error)
                })
        },

        updateSettings() {
            const {id} = this.user
            const data = this.user
            api.auth.setMe(id, data)
                .then(() => {
                    this.showToast('success', 'Успешно создано!', 'CheckIcon')
                })
                .catch((error) => {
                    this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                    console.log(error)
                })
        },

        async updatePassword() {
            const data = {
                old_password: this.password.old_password,
                new_password: this.password.new_password
            }
            await api.auth.updatePassword(data)
                .then(() => {
                    this.showToast('success', 'Успешно создано!', 'CheckIcon')
                })
                .catch((error) => {
                    this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                    console.log(error)
                })
        }

    }
}
</script>

<style lang="scss" scoped>
.validation__red {
    color: red;
    font-size: 12px;
    display: block;
}
</style>
